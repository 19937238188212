<template>
    <b-container v-if="sessionData">
      <b-alert variant="danger" v-if="error" dismissible show>{{ error }}</b-alert>
      <h2>IRIS Payment Page</h2>
      <p class="my-2">Payment for: {{ formatter.format(sessionData.amount) }}
        <template v-if="sessionData.paymentDate">
          to be paid on {{ d(sessionData.paymentDate) }}
        </template>
      </p>
      <div v-if="sessionData.state !== RecurlyRemotePaymentState.Success">
        <b-alert variant="warning" show v-if="sessionData.state === RecurlyRemotePaymentState.Failure">{{ sessionData.message }}</b-alert>
        <b-alert variant="primary" v-if="cardAuthActive" show>Please complete card authentication</b-alert>
        <b-alert variant="primary" v-else-if="processing" show>Submitting card payment</b-alert>
        <div>Card Details:</div>
        <b-form-row>
          <b-form-group class="col-12" :state="userErrors.length === 0">
            <div ref="cardElement"></div>
            <div v-for="msg in userErrors" :key="msg" slot="invalid-feedback">{{ msg }}</div>
          </b-form-group>
        </b-form-row>
        <b-form-row class="my-2">
          <b-col>
            <b-button variant="primary" :disabled="!cardDetailsOk || processing" @click="doPayment">{{ sessionData.paymentDate ? 'Save card details' : 'Process payment' }}</b-button>
          </b-col>
        </b-form-row>
      </div>
      <div v-else>
        <b-alert variant="success" show>{{ sessionData.message }}</b-alert>
      </div>
      <div v-if="cardAuthActive" ref="authContainer"></div>
    </b-container>
    <b-container v-else-if="error">
      <b-alert variant="danger" show>{{ error }}</b-alert>
    </b-container>
  </template>
  
  <script lang="ts" setup>

  import { useRecurlyElements } from '@iris/components/payments/recurly'
import { DateTime } from 'luxon';
import { Ref } from 'vue/types';
import { useRecurlyRemotePaymentUpdatesSubscription, useRecurlyUpdateSessionMutation, RecurlyRemotePaymentState, RecurlyRemotePaymentFragment, Maybe } from '~~/iris/src/queries'

const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

const route = useRoute()

  const { public: { recurlyPublicKey } } = useRuntimeConfig()

  const canEdit = ref(true)
  const processing = ref(false)
  const error = ref(null)

  const sessionData = ref<RecurlyRemotePaymentFragment>()

  const subscription = useRecurlyRemotePaymentUpdatesSubscription(computed(() => ({
    id: route.query.id as string,
    frontend: true
  })))

  subscription.onResult((data) => {
    error.value = null
    sessionData.value = data.data.recurlyRemotePaymentWatcher
  })
  subscription.onError(e => {
    error.value = e
  })

  const updateSession = useRecurlyUpdateSessionMutation({})

  updateSession.onDone((result) => {
    sessionData.value = result.data.recurlyUpdateSession
  })

  updateSession.onError((e) => {
    error.value = e
  })

  const d = (value?: Maybe<string> | Ref<Maybe<string> | undefined>): string => {
        value = unref(value)
        if (value) {
          return DateTime.fromISO(value).toFormat('dd/MM/yyyy')
        }
        return ''
      }


  const { userErrors, cardAuthActive, cardDetailsOk, do3DSecure, initElements, tokeniseCard, recurlyData } = useRecurlyElements({
    cardElementRefName: 'cardElement',
    authContainerRefName: 'authContainer',
    userDetauls: computed(() => {
      return sessionData.value.userInfo
    }),
    publicKey: recurlyPublicKey
  })

  // recurlydata watcher
  watch(recurlyData, (recurly, old) => {
    const processUpdate = (state: null | RecurlyRemotePaymentState) => {
      error.value = null
      return updateSession.mutate({
            id: route.query.id as string,
            state,
            cardDetails: {
              brand: recurly.brand,
              firstSix: recurly.firstSix,
              lastFour: recurly.lastFour,
              expMonth: null,
              expYear: null,
              fundingSource: null,
              issuingCountry: null
            }
          })
    }
    // sessiondata not ready
    if (!sessionData.value || !recurly) {
      return
    }
    switch(sessionData.value.state) {
      case(null):
      case(RecurlyRemotePaymentState.Failure):
      // initial case no focus and bad card details
        if (recurly.focus) {
          return processUpdate(RecurlyRemotePaymentState.EnteringCardDetails)
        }
      break;
      case(RecurlyRemotePaymentState.EnteringCardDetails):
          // user has focus on widget but card details are not valid
        if (recurly.valid) {
          return processUpdate(RecurlyRemotePaymentState.CardDetailsGood)
          }
          // loose focus jump to null
        if (!recurly.focus) {
          return processUpdate(null)
        }
      break;
      case(RecurlyRemotePaymentState.CardDetailsGood):
        // user nas entered all details and can press pay but hasn't done so
        if (!recurly.valid) {
          // no longer valid
          return processUpdate(RecurlyRemotePaymentState.EnteringCardDetails)
        }
      break;
    }
  }, {
    deep: true
  })

  // only handle 3ds if the payment button was pressed
  const do3DsPayment = () => {
        return do3DSecure(sessionData.value.threeDSessionID).then((resp) => {
          sessionData.value.state = RecurlyRemotePaymentState.SubmittingCardDetails
          return updateSession.mutate({
            id: route.query.id as string,
            state: RecurlyRemotePaymentState.SubmittingCardDetails,
            threeDToken: {
              token: resp.id,
              type: resp.type
            },
            cardToken: {
              token: sessionData.value.token.token,
              type:  sessionData.value.token.type
            }
          })

        }).catch(e => {
          // failure in 3ds
          return updateSession.mutate({
            id: route.query.id as string,
            message: e.message,
            state: RecurlyRemotePaymentState.Failure
          })
        })
  }


  const doPayment = () => {
    processing.value = true
    sessionData.value.state = RecurlyRemotePaymentState.SubmittingCardDetails
    return tokeniseCard((token) => {
      return updateSession.mutate({
        id: route.query.id as string,
        state: RecurlyRemotePaymentState.SubmittingCardDetails,
        cardDetails: {
          brand: token.card.brand,
          expMonth: token.card.exp_month,
          expYear: token.card.exp_year,
          firstSix: token.card.first_six,
          fundingSource: token.card.funding_source,
          issuingCountry: token.card.issuing_country,
          lastFour: token.card.last_four
        },
        cardToken: {
          token: token.id,
          type: token.type
        }
      }).then(() => new Promise<string>((resolve, reject) => {
        const stopEffect = watchEffect(() => {
          // 3ds is being asked for start it up!
          if (sessionData.value.state === RecurlyRemotePaymentState.ThreeDSecureRequired) {
            // init 3ds as it's being asked for only on this client!
            do3DsPayment()
          }
          if (sessionData.value.state === RecurlyRemotePaymentState.Failure) {
            stopEffect()
            return reject(new Error(sessionData.value.message))
          }
          if (sessionData.value.state === RecurlyRemotePaymentState.Success) {
            stopEffect()
            return resolve(sessionData.value.message)
          }
        })
      }))
    }).catch((e) => {
      sessionData.value.state = RecurlyRemotePaymentState.CardDetailsGood
      error.value = e.message || e
    }).finally(() => {
        processing.value = false
      })
  }

  const disableInitialWatcher = watchEffect(() => {
    // console.log('initial watcher!')
    if (sessionData.value) {
      disableInitialWatcher()
      // console.log('initial watch state is null init elements')
      if (sessionData.value.state !== RecurlyRemotePaymentState.Success) {
        return Promise.all([
          Promise.resolve().then(() => {
            if(sessionData.value.state !== null) {
              return updateSession.mutate({
                  id: route.query.id as string,
                  state: null,
                  cardDetails: {
                    brand: null,
                    firstSix: null,
                    lastFour: null,
                    expMonth: null,
                    expYear: null,
                    fundingSource: null,
                    issuingCountry: null
                  }
              })
            }
          }),
          nextTick().then(() => initElements())
        ])
      }
    }
  })
    
  </script>
  
  
<style>
@import url('https://js.recurly.com/v4/recurly.css');
</style>
  